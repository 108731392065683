import {TipoNovedadConstants} from "@/modules/percepcionesAsignacionFamiliar/constants/tipoNovedad";

export const percepcionMaternidadForm = (selectedAsignacionFamiliar) => {
    return {
        year: new Date().getFullYear(),
        tipo_percepcion: selectedAsignacionFamiliar?.percepcion_maternidad?.tipo_percepcion ?? null,
        tipo_novedad: selectedAsignacionFamiliar?.percepcion_maternidad?.tipo_novedad ?? TipoNovedadConstants.ALTA,
        maternidad_dias_previos_fpp: selectedAsignacionFamiliar?.percepcion_maternidad?.maternidad?.dias_previos_fpp ?? null,
        maternidad_fecha_notificacion: selectedAsignacionFamiliar?.percepcion_maternidad?.maternidad?.fecha_notificacion ?? null,
        maternidad_fecha_inicio: selectedAsignacionFamiliar?.percepcion_maternidad?.maternidad?.fecha_inicio ?? null,
        maternidad_fecha_fin: selectedAsignacionFamiliar?.percepcion_maternidad?.maternidad?.fecha_fin ?? null,

        maternidad_down_fecha_fin_licencia: selectedAsignacionFamiliar?.percepcion_maternidad?.maternidad_down?.fecha_fin_licencia_maternidad ?? null,
        maternidad_down_cuil_hijo: selectedAsignacionFamiliar?.percepcion_maternidad?.maternidad_down?.cuil_hijo ?? null,
        maternidad_down_dni_hijo: selectedAsignacionFamiliar?.percepcion_maternidad?.maternidad_down?.dni_hijo ?? null,
        maternidad_down_nombre_hijo: selectedAsignacionFamiliar?.percepcion_maternidad?.maternidad_down?.nombre_hijo ?? null,
        maternidad_down_tipo_partida: selectedAsignacionFamiliar?.percepcion_maternidad?.maternidad_down?.tipo_partida ?? null,
        maternidad_down_numero_partida: selectedAsignacionFamiliar?.percepcion_maternidad?.maternidad_down?.numero_partida ?? null,
        maternidad_down_numero_tomo: selectedAsignacionFamiliar?.percepcion_maternidad?.maternidad_down?.numero_tomo ?? null,
        maternidad_down_numero_folio: selectedAsignacionFamiliar?.percepcion_maternidad?.maternidad_down?.numero_folio ?? null,

        certificado_medico_certificado: selectedAsignacionFamiliar?.percepcion_maternidad?.certificado_medico?.certificado ?? '',
        certificado_medico_certificado_original_name: selectedAsignacionFamiliar?.percepcion_maternidad?.certificado_medico?.original_name ?? 'Modificar certificado médico',

    }
}

